<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                   <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                     <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col>
                <v-card color="blue lighten-5">
                    <v-container fluid>
                            <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="company" 
                                    :items="companys" 
                                    default="" 
                                    item-value="company_id" 
                                    item-text="company_name" 
                                    label="Company ID"
                                    prepend-icon="mdi-home-variant"
                                    clearable
                                    @change="(event) => [UpdateOffice(event), UpdateIdemploye(event)]"
                                    :disabled="disabled_company"
                                    >
                                </v-autocomplete>
                                </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="office" 
                                    :items="offices" 
                                    default="" 
                                    item-value="office_id" 
                                    item-text="office_name" 
                                    label="Office ID" 
                                    clearable
                                    prepend-icon="mdi-store"
                                    @change="(event) => [UpdateDepart(event), UpdateIdemploye(event)]"
                                     :disabled="disabled_office"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="depart" 
                                    :items="departs" 
                                    default="" 
                                    item-value="dept_id" 
                                    item-text="dept_name" 
                                    label="Department"
                                    clearable
                                    prepend-icon="mdi-home-assistant"
                                    @change="(event) => [UpdateDivisi(event), UpdateIdemploye(event)]"      
                                    >
                                </v-autocomplete>
                                </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="divis" 
                                    :items="divisions" 
                                    default="" 
                                    item-value="division_id" 
                                    item-text="division_name" 
                                    label="Division"
                                    clearable
                                    prepend-icon="mdi-flag-triangle"
                                    @change="(event) => [UpdateIdemploye(event), UpdateIdemploye(event)]"  
                                    >
                                </v-autocomplete>
                                </v-col> 
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="employee" 
                                    :items="employes" 
                                    default="" 
                                    item-value="idemployee" 
                                    item-text="idemployee" 
                                    label="Employee ID" 
                                    clearable
                                    prepend-icon="mdi-library" 
                                    @change="(event) => [UpdateName(event), UpdateIdemploye(event)]"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="namex" 
                                    :items="names" 
                                    default="" 
                                    item-value="name" 
                                    item-text="name" 
                                    label="Name" 
                                    clearable
                                    prepend-icon="mdi-emoticon"
                                    @change="(event) => [UpdateKeterangan(event), UpdateIdemploye(event)]"
                                    >
                                </v-autocomplete>
                                </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete solo
                                    class="ma-0 pa-2"
                                    hide-details=true 
                                    v-model="keterangan" 
                                    :items="keterangans" 
                                    default="" 
                                    item-value="str1" 
                                    item-text="str2" 
                                    label="Deskripsi"
                                    clearable
                                    prepend-icon="mdi-tooltip-text"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date to"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-3 ml-2" 
                                    color="info" elevation="2" large @click="getPullData()">Search</v-btn>
                            </v-col>
                            </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-dialog v-model="dialog" max-width="400px">                
                <v-card>
                <v-progress-linear
                        :active="loading_dialog"
                        :indeterminate="loading_dialog"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                ></v-progress-linear>
                <v-card-title>Upload File Image</v-card-title>
                <v-card-text>
                    <v-container fluid>
                    <v-row>
                     <v-spacer></v-spacer>
                        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" accept="image/*">
                    </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-col cols="6" xs="12" sm="12" md="6">
                    <v-btn
                    class="mr-2 text-white"
                    color="#053d76"
                    elevation="2"
                    large
                    @click="saveImage()"
                    >Save</v-btn>
                    <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
            </v-dialog>
        <v-dialog
        v-model="dialog_file_doc"
       
        max-width="800px"
        >
        <v-card class="p-2 rounded-l">
            <v-card-title class="text-center">
                <v-row>
                    <h4
                    class="text-center"
                    style="
                    font-weight: 600;
                    color: #053d76;
                    margin-bottom: 15px;
                    margin-top: 2px;">
                    FILE (IMAGE)
                    </h4>
                <v-divider
                    style="
                    border-top: 3px solid #0078d4;
                    margin-top: 5px;"></v-divider>
                </v-row>
            </v-card-title>
             <v-card-text>
                <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
                    <v-row>
                        <v-col class="col-12" sm="4" md="12">
                            <v-card class="elevation-1 rounded-5">
                                <v-card-text>
                                    <v-row justify="center" align="center" class="mt-12" v-if="userModel === null">
                                        <v-col cols="3">
                                        </v-col>
                                    </v-row>
                                        <v-card-text class="mt-3">
                            <v-carousel
                                progress
                                cycle
                                interval="10000"
                                show-arrows-on-hover
                            >
                                <v-carousel-item
                                   v-for="file in doc_files" :key="file.file_name" :src="file.file_name"
                                    lazy-src="https://picsum.photos/id/11/100/60"
                                    reverse-transition="fade-transition"
                                    transition="fade-transition"
                                    contain
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-carousel-item>
                            </v-carousel>
                        </v-card-text>
                                    <!-- <v-img v-for="file in doc_files" :key="file.file_name" :src="file.file_name"></v-img> -->
                                    <p class="css-bqy72x mt-5">Besar file: maksimum (1 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG</p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col class="col-12" sm="8" md="12">
                            <v-container fluid>
                                 <v-row class="mt-12">
                                            <v-col cols="12">
                                                <v-alert
                                                icon="mdi-shield-lock-outline"
                                                prominent
                                                text
                                                color="red"
                                                >
                                                Cek kembali data setiap user pastikan data benar dan tidak ada kesalahan input data user
                                                </v-alert>
                                        </v-col>
                                </v-row>
                            </v-container>
                        </v-col>                      
                        </v-row>
                        </v-container>
                    </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn block class="rounded-l mr-2 text-white"  color="red" @click.stop="dialog_file_doc=false">Close</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
               <v-row class="mt-2" style="padding-bottom:50px">
                <v-col cols="12">
                    <v-card class="rounded-l elevation-5 ml-3" color="blue lighten-5">
                    <v-card-text>
                         <h5>Result</h5>
                        <v-data-table
                            dense
                            :headers="headers"
                            :loading="loading"
                            :items="menu_headers"
                            loading-text="Please wait, retrieving data"
                            class="elevation-1"
                            >  
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-chip class="ma-2" color="success">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <div v-if="item.type_code === 'SD'">
                                                <v-icon small class="mr-2" @click="upload(item)" v-on="on">
                                                    mdi-upload
                                                </v-icon>
                                                Upload
                                            </div>
                                            <div v-else>
                                                <span class="grey--text text--lighten-1">Unable to upload</span>
                                            </div>
                                        </template>
                                        <span> Upload</span>
                                    </v-tooltip>
                                    
                                </v-chip>
                                </template>
                                     <template v-slot:[`item.actionss`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small color="blue" class="mr-2" @click="showFileDetail(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span> Show File</span>
                                        </v-tooltip>
                                 </template>
                         </v-data-table>
                       </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>

<script>
import {filter} from "../../../backend-api/filter/index"
import {backendApi} from "../../../backend-api/backend-api-sr"
export default {
    data(){
        return {
            loading: false,
                snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'Viva',
                    disabled: false,
                    href: '/admin/viva',
                },
                {
                    text: 'HRIS',
                    disabled: false,
                    href: '#',
                },
                {
                    text: 'Employee Permission',
                    disabled: true,
                }
            ],
            menu_headers:[],
            headers:[
                {
                text: 'Company ID',
                align: 'start',
                value: 'company_id', 
                align:'center'},
                { text: 'Office ID', value: 'office_id' , align:'center'},
                { text: 'ID Employee', value: 'idemployee' , align:'center'},
                { text: 'Name', value: 'name' , align:'left'},
                { text: 'Deskripsi', value: 'str2' , align:'left'},
                { text: 'Keterangan', value: 'keterangan' , align:'center'},
                 { text: 'Tr Date', value: 'tr_date' , align:'center'},
                // { text: 'Upload File', value: 'actions' , align:'center'},
                { text: 'Detail', value: 'actionss' , align:'center'},
            ],
            doc_files: [],
            offices:[],
            userModel:[],
            names:[],
            employes:[],
            date_from: '',
            departs:[],
            depart:'',
            divisions:[],
            divis:'',
            modal: false,
            date_to: '',
            modal_to: false,
            dialog: false,
            loading_dialog: false,
            dialog_file_doc:false,
            companys:[],
            namex:'',
            company:'',
            employees:[],
            employee:'',
            names:[],
            name:'',
            office:'',
            keterangans:[],
            keterangan:'',
            disabled_company: false,
            disabled_office: false

        }
    },
    mounted (){
    this.getCompany()
    this.getEnvConf()
    this.UpdateKeterangan()

    },
    methods: {
        async getEnvConf(){
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=VIVA&appl_id=WEBVIVA`, null, false, false, false)
            if (respData.status === 200) {
               // console.log(respData.data)
                this.group_id = respData.data.data
                
               
            const respCompany_id = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=VIVA&appl_id=WEBVIVA&var_id=COMPANY_ID&groupid=${this.group_id}`, null, false, false, false) 

            //console.log(respCompany_id)
            if (respCompany_id.data.data != null) {
                this.company = respCompany_id.data.data
                this.disabled_company = true
                this.UpdateIdemploye()
                this.UpdateDepart()
                this.UpdateDivisi()
                this.UpdateKeterangan()
                
            } else {
                this.company = ''
                this.disabled_company = false
            }
            const respOffice_id = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=VIVA&appl_id=WEBVIVA&var_id=OFFICE_ID&groupid=${this.group_id}`, null, false, false, false) 

           // console.log(respOffice_id)
            if (respOffice_id.data.data != null) {
                this.office = respOffice_id.data.data
                this.disabled_office = true
                this.UpdateOffice()
                this.UpdateIdemploye()
                this.UpdateDivisi()
                this.UpdateName()
                this.UpdateKeterangan()


            } else {
                this.office = ''
                this.disabled_office = false
            }
                    
            }
                
        },
    close(){
          this.dialog = false
        },
     async getCompany(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/listemploye/GetCompany`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.companys = res.data
            })
        },

        async getDivisi(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/listemploye/GetCompany?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&idemployee=${this.employee ? this.employee : ""}&name=${this.namex ? this.namex : ""}&office_id=${this.office ? this.office : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.divisions = res.data
            })
        },


         async UpdateOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/listemploye/GetOffice?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&office_id=${this.office ? this.office : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data
            })
        },

        async UpdateIdemploye(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/permission/GetCompany?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&office_id=${this.office ? this.office : ""}&dept_id=${this.depart ? this.depart : ""}&division_id=${this.divis ? this.divis : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
           // console.log(res.data)
             this.employes = res.data.datas
            })
        },  
      
        async UpdateName(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/permission/GetCompany?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&office_id=${this.office ? this.office : ""}&dept_id=${this.depart ? this.depart : ""}&division_id=${this.divis ? this.divis : ""}&idemployee=${this.employee ? this.employee : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
             this.names = res.data.datas
            })
        },  

        async UpdateDivisi(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/listemploye/Divis?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&office_id=${this.office ? this.office : ""}&dept_id=${this.depart ? this.depart : ""}&division_id=${this.divis ? this.divis : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.divisions = res.data.datas
                
            })
        },

        async UpdateDepart(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/listemploye/Dept?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&office_id=${this.office ? this.office : ""}&dept_id=${this.depart ? this.depart : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
             this.departs = res.data.datas
             
            })
        },

        async getIndex(item){
            this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/permission`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // console.log(res.data)
                this.menu_headers = res.data.datas
            })
        },


    async UpdateKeterangan(item){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/permission/keterangan?search=${this.$store.state.text}&str3=${this.company ? this.company : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.keterangans = res.data.datas
            })
        },
     showFileDetail(item){
          console.log(item.name);
          this.doc_id = item
          this.company_id = item
          this.office_id = item
          this.idemployee = item
          this.keterangan = item    
          this.name = item
          this.keterangan = item
          this.office_name = item
          this.keterangan = item
          this.type_code = item
          this.dialog_file_doc = true
          this.getFileDocImage()
          //this.getIndex()
        },
         async getFileDocImage(item){
          await axios.get(`${process.env.VUE_APP_URL}/api/viva/permission/Getfile?search=${this.$store.state.text}&company_id=${this.company_id ? this.company_id.company_id : ""}&office_id=${this.office_id ? this.office_id.office_id : ""}&idemployee=${this.idemployee ? this.idemployee.idemployee : ""}&name=${this.name ? this.name.name : ""}&keterangan=${this.keterangan ? this.keterangan.keterangan : ""}&doc_id=${this.doc_id ? this.doc_id.doc_id : ""}`, { 
            // await axios.get(`${process.env.VUE_APP_URL}/api/viva/permission/Getfile?search=${this.$store.state.text}&company_id=${this.company_id ? this.company_id.company_id : ""}&office_id=${this.office_id ? this.office_id.office_id : ""}&idemployee=${this.idemployee ? this.idemployee.idemployee : ""}&dept_id=${this.dept_id ? this.dept_id.dept_id : ""}&division_id=${this.division_id ? this.division_id.division_id : ""}&section_id=${this.section_id ? this.section_id.section_id : ""}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
            console.log(res.data)
            this.doc_files = res.data;
            this.doc_files = this.doc_files.map(file => {
            file.file_name = process.env.VUE_APP_URL + '/img/hrd/emp/izin/' + file.file_name;
            return file;
        });
    });
        
    },

    upload(item){
        this.dialog = true
       // console.log(item)
        this.company_id = item
        this.idemployee = item
        this.name = item
        this.office_id = item
        this.keterangan = item
    },
    handleFileUpload(){
        this.file = this.$refs.file.files[0];
        //console.log(this.file);
    },
    saveImage(item){
            this.loading_dialog = true
            let formData = new FormData();
            formData.append('company_id', this.company_id.company_id);
            formData.append('office_id', this.office_id.office_id);
            formData.append('idemployee', this.idemployee.idemployee);
            formData.append('name', this.name.name);
            formData.append('keterangan', this.keterangan.keterangan);
            formData.append('file', this.file);
            axios.post(`${process.env.VUE_APP_URL}/api/viva/permission/upload`, formData , 
            { 
              headers: { 'Authorization': `Bearer ${this.$store.getters.isTokenUser}`, 'Content-Type': 'multipart/form-data' } 
            }).then(res => {
                this.loading_dialog = false
                this.dialog = false
                this.file = ''
                this.snackbar = {
                  color: "success",
                  icon: "mdi-checkbox-marked-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Success",
                  text: "Successfully Upload File",
                  visible: true
                };
            }).catch(err => {
                this.loading_dialog = false
                 this.snackbar = {
                  color: "error",
                  icon: "mdi-checkbox-marked-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: err.response.data.message,
                  visible: true
                };
            })

        }, 
    async getPullData(){
     this.loading = true
     this.$store.dispatch('setOverlay', true)
        if (this.company == '' ||  this.date_fromm == '' || this.date_too == '') {
            this.$store.dispatch('setOverlay', false)
            this.loading = false
            this.loading_dialog = false
                 this.snackbar = {
                  color: "error",
                  icon: "mdi-checkbox-marked-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please Input Office ID & Start Date or End Date",
                  visible: true
            };
        } else {
        // var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
        // var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
        var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '';
        var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : new Date().toISOString().substr(0, 10).replace(/-/g,"");
        await axios.get(`${process.env.VUE_APP_URL}/api/viva/permission/getData?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&officef_id=${this.offifce ? this.offifce : ""}&dept_id=${this.depart ? this.depart : ""}&idemployee=${this.employee ? this.employee : ''}&division_id=${this.divis ? this.divis : ""}&idemployee=${this.employee ? this.employee : ""}&name=${this.namex ? this.namex : ""}&str1=${this.keterangan ? this.keterangan : ""}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.loading = false
            this.$store.dispatch('setOverlay', false)
           // console.log(this.keterangan)
            this.menu_headers = res.data.datas
        })
            
        }
       
    },

    }

}
</script>

<style>

</style>